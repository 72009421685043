import { Options } from '@hey-api/client-fetch';

import { ApiError } from '@Api/ApiError';
import DefaultResponseModel from '@Api/models/DefaultResponseModel';
import LoginModel from '@Api/models/LoginModel';
import ModBannedUsernamesModel from '@Api/models/ModBannedUsernamesModel';
import ModFlaggedUserListModel from '@Api/models/ModFlaggedUserListModel';
import NotificationSettingModel from '@Api/models/NotificationSettingModel';
import ProfileModel from '@Api/models/ProfileModel';
import RefreshModel from '@Api/models/RefreshModel';
import UserActivityHeatmapValueModel from '@Api/models/UserActivityHeatmapValueModel';
import UserActivityRadarModel from '@Api/models/UserActivityRadarModel';
import UserModel from '@Api/models/UserModel';
import ValidationErrorModel from '@Api/models/ValidationErrorModel';
import { useApi } from '@Api/useApi';

import {
  completeUserPasswordRecovery,
  type CompleteUserPasswordRecoveryData,
  DefaultResponse,
  getNotificationsSettings,
  type GetNotificationsSettingsResponse,
  getProfileByMod,
  type GetProfileByModData,
  getProfileMe,
  getUserActivityHeatmap,
  type GetUserActivityHeatmapData,
  type GetUserActivityHeatmapResponse,
  getUserActivityRadar,
  type GetUserActivityRadarData,
  getUserMe,
  getUserSettings,
  login,
  type LoginData,
  LoginResponse,
  ModFlaggedUserListResponse,
  modGetBannedUsernamesCsv,
  type ModGetBannedUsernamesCsvResponse,
  modListBannedUsernames,
  type ModListBannedUsernamesResponse,
  modListFlaggedUsers,
  ModListFlaggedUsersData,
  modListUsersWithBannedNames,
  ModListUsersWithBannedNamesResponse,
  modUpdateBannedUsernames,
  type ModUpdateBannedUsernamesData,
  ModUpdateBannedUsernamesResponse,
  NotificationSettingResponse,
  ProfileResponse,
  refresh,
  RefreshData,
  RefreshResponse,
  updateNotificationSettings,
  type UpdateNotificationSettingsData,
  type UpdateNotificationSettingsResponse,
  updateProfile,
  type UpdateProfileData,
  updateProfileTheme,
  type UpdateProfileThemeData,
  updateUserModFlags,
  type UpdateUserModFlagsData,
  updateUserPassword,
  type UpdateUserPasswordData,
  updateUserPrimaryEmail,
  type UpdateUserPrimaryEmailData,
  UserActivityHeatmapValueResponse,
  UserActivityRadarResponse,
  userDelete,
  type UserDeleteData,
  userDeleteSocialAuth,
  type UserDeleteSocialAuthData,
  userPasswordRecovery,
  type UserPasswordRecoveryData,
  userRegistration,
  type UserRegistrationData,
  userResendRegistrationMail,
  UserResendRegistrationMailData,
  UserResponse,
} from '@Api-generated';

const hydrateDefaultResponse = (apiResponse: DefaultResponse): DefaultResponseModel => {
  return DefaultResponseModel.hydrateFromApi(apiResponse);
};

const hydrateUser = (apiResponse: UserResponse): UserModel => {
  return UserModel.hydrateFromApi(apiResponse);
};

const hydrateUserList = (response: { data: UserResponse[] }): UserModel[] => {
  return response.data.map((user) => hydrateUser(user));
};

const hydrateProfile = (apiResponse: ProfileResponse): ProfileModel => {
  return ProfileModel.hydrateFromApi(apiResponse);
};

const hydrateNotificationSetting = (apiResponse: NotificationSettingResponse): NotificationSettingModel => {
  return NotificationSettingModel.hydrateFromApi(apiResponse);
};

const hydrateModUserList = (apiResponse: ModFlaggedUserListResponse): ModFlaggedUserListModel => {
  return ModFlaggedUserListModel.hydrateFromApi(apiResponse);
};

const hydrateListNotificationSetting = (response: {
  data: NotificationSettingResponse[];
}): NotificationSettingModel[] => {
  return response.data.map((notificationSetting) => hydrateNotificationSetting(notificationSetting));
};

const hydrateUserActivityHeatmapValue = (
  apiResponse: UserActivityHeatmapValueResponse
): UserActivityHeatmapValueModel => {
  return UserActivityHeatmapValueModel.hydrateFromApi(apiResponse);
};

const hydrateUserActivityHeatmap = (response: {
  data: UserActivityHeatmapValueResponse[];
}): UserActivityHeatmapValueModel[] => {
  return response.data.map((userActivity) => hydrateUserActivityHeatmapValue(userActivity));
};

const hydrateUserActivityRadar = (apiResponse: UserActivityRadarResponse): UserActivityRadarModel =>
  UserActivityRadarModel.hydrateFromApi(apiResponse);

const hydrateLoginResponse = (apiResponse: LoginResponse): LoginModel => {
  return LoginModel.hydrateFromApi(apiResponse);
};

const hydrateRefreshResponse = (apiResponse: RefreshResponse): RefreshModel => {
  return RefreshModel.hydrateFromApi(apiResponse);
};

const hydrateModListBannedUsernames = (apiResponse: ModListBannedUsernamesResponse): ModBannedUsernamesModel =>
  ModBannedUsernamesModel.hydrateFromApi(apiResponse);

export function useLogin(
  onSuccess?: (result: LoginModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<LoginData>, LoginModel, LoginResponse>(
    login,
    hydrateLoginResponse,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useRefresh(
  onSuccess?: (result: RefreshModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<RefreshData>, RefreshModel, RefreshResponse>(
    refresh,
    hydrateRefreshResponse,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useGetUserMe(
  onSuccess?: (result: UserModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options, UserModel, UserResponse>(getUserMe, hydrateUser, onSuccess, onError, onValidationError);
}

export function useGetUserSettings(
  onSuccess?: (result: UserModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options, UserModel, UserResponse>(getUserSettings, hydrateUser, onSuccess, onError, onValidationError);
}

export function useDeleteUserSocialAuth(
  onSuccess?: (result: DefaultResponseModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<UserDeleteSocialAuthData>, DefaultResponseModel, DefaultResponse>(
    userDeleteSocialAuth,
    hydrateDefaultResponse,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useRegistration() {
  return useApi<Options<UserRegistrationData>, DefaultResponseModel, DefaultResponse>(
    userRegistration,
    hydrateDefaultResponse
  );
}

export function useResendRegistrationMail(
  onSuccess?: (result: DefaultResponseModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<UserResendRegistrationMailData>, DefaultResponseModel, DefaultResponse>(
    userResendRegistrationMail,
    hydrateDefaultResponse,
    onSuccess,
    onError,
    onValidationError
  );
}

export function usePasswordRecovery() {
  return useApi<Options<UserPasswordRecoveryData>, DefaultResponseModel, DefaultResponse>(
    userPasswordRecovery,
    hydrateDefaultResponse
  );
}

export function useCompletePasswordRecovery(
  onSuccess?: (result: DefaultResponseModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<CompleteUserPasswordRecoveryData>, DefaultResponseModel, DefaultResponse>(
    completeUserPasswordRecovery,
    hydrateDefaultResponse,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useGetProfileMe(
  onSuccess?: (result: ProfileModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options, ProfileModel, ProfileResponse>(
    getProfileMe,
    hydrateProfile,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useGetProfileByMod(
  onSuccess?: (result: ProfileModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<GetProfileByModData>, ProfileModel, ProfileResponse>(
    getProfileByMod,
    hydrateProfile,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useModListFlaggedUsers(
  onSuccess?: (result: ModFlaggedUserListModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<ModListFlaggedUsersData>, ModFlaggedUserListModel, ModFlaggedUserListResponse>(
    modListFlaggedUsers,
    hydrateModUserList,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useUpdateUserModFlags(
  onSuccess?: (result: UserModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<UpdateUserModFlagsData>, UserModel, UserResponse>(
    updateUserModFlags,
    hydrateUser,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useUpdateSettingsProfile(
  onSuccess?: (result: ProfileModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<UpdateProfileData>, ProfileModel, ProfileResponse>(
    updateProfile,
    hydrateProfile,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useUpdateProfileTheme(
  onSuccess?: (result: UserModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<UpdateProfileThemeData>, UserModel, UserResponse>(
    updateProfileTheme,
    hydrateUser,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useUpdateUserPrimaryEmail(
  onSuccess?: (result: DefaultResponseModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<UpdateUserPrimaryEmailData>, DefaultResponseModel, DefaultResponse>(
    updateUserPrimaryEmail,
    hydrateDefaultResponse,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useUpdateUserPassword(
  onSuccess?: (result: DefaultResponseModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<UpdateUserPasswordData>, DefaultResponseModel, DefaultResponse>(
    updateUserPassword,
    hydrateDefaultResponse,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useDeleteUser(
  onSuccess?: (result: DefaultResponseModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<UserDeleteData>, DefaultResponseModel, DefaultResponse>(
    userDelete,
    hydrateDefaultResponse,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useListNotificationSettings(
  onSuccess?: (result: NotificationSettingModel[]) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options, NotificationSettingModel[], GetNotificationsSettingsResponse>(
    getNotificationsSettings,
    hydrateListNotificationSetting,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useUpdateNotificationSettings(
  onSuccess?: (result: NotificationSettingModel[]) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<
    Options<UpdateNotificationSettingsData>,
    NotificationSettingModel[],
    UpdateNotificationSettingsResponse
  >(updateNotificationSettings, hydrateListNotificationSetting, onSuccess, onError, onValidationError);
}

export function useGetUserActivityHeatmap(
  onSuccess?: (result: UserActivityHeatmapValueModel[]) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<GetUserActivityHeatmapData>, UserActivityHeatmapValueModel[], GetUserActivityHeatmapResponse>(
    getUserActivityHeatmap,
    hydrateUserActivityHeatmap,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useGetUserActivityRadar(
  onSuccess?: (result: UserActivityRadarModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<GetUserActivityRadarData>, UserActivityRadarModel, UserActivityRadarResponse>(
    getUserActivityRadar,
    hydrateUserActivityRadar,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useModListBannedUsernames(
  onSuccess?: (result: ModBannedUsernamesModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<unknown>, ModBannedUsernamesModel, ModListBannedUsernamesResponse>(
    modListBannedUsernames,
    hydrateModListBannedUsernames,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useModUpdateBannedUsernames(
  onSuccess?: (result: ModBannedUsernamesModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<ModUpdateBannedUsernamesData>, ModBannedUsernamesModel, ModUpdateBannedUsernamesResponse>(
    modUpdateBannedUsernames,
    hydrateModListBannedUsernames,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useModListUserWithBannedNames(
  onSuccess?: (result: UserModel[]) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<unknown>, UserModel[], ModListUsersWithBannedNamesResponse>(
    modListUsersWithBannedNames,
    hydrateUserList,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useModGetBannedUsernamesCSV(
  onSuccess?: (result: File | Blob) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<unknown>, File | Blob, ModGetBannedUsernamesCsvResponse>(
    modGetBannedUsernamesCsv,
    (response) => response,
    onSuccess,
    onError,
    onValidationError
  );
}

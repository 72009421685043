import { TextPracticeRatingReceivedResponse } from '@Api-generated';

export default class TextPracticeRatingReceivedModel implements TextPracticeRatingReceivedResponse {
  public textPracticeRatingId: string;
  public slug: string;
  public title: string;
  public rating: number;

  constructor(textPracticeRatingId: string, slug: string, title: string, rating: number) {
    this.textPracticeRatingId = textPracticeRatingId;
    this.slug = slug;
    this.title = title;
    this.rating = rating;
  }

  public static hydrateFromApi(textPracticeRatingReceivedResponse: TextPracticeRatingReceivedResponse) {
    return new TextPracticeRatingReceivedModel(
      textPracticeRatingReceivedResponse.textPracticeRatingId,
      textPracticeRatingReceivedResponse.slug,
      textPracticeRatingReceivedResponse.title,
      textPracticeRatingReceivedResponse.rating
    );
  }
}

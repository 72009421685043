import { ModListBannedUsernamesResponse } from '@Api-generated';

export default class ModBannedUsernamesModel implements ModListBannedUsernamesResponse {
  public bannedUsernames: string;

  constructor(bannedUsernames: string) {
    this.bannedUsernames = bannedUsernames;
  }

  public static hydrateFromApi(
    modListBannedUsernamesResponse: ModListBannedUsernamesResponse
  ): ModBannedUsernamesModel {
    return new ModBannedUsernamesModel(modListBannedUsernamesResponse.bannedUsernames);
  }
}
